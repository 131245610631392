type LanguageCode = {
  label: string;
  code: string;
};

type SupportedLanguages = {
  [key: string]: LanguageCode;
};

export const LANGUAGE_FERRY_TERM_COMBINATIONS: { [key: string]: string } = {
  da: "faerge",
  de: "faehre",
  el: "aktoploika",
  en: "ferry",
  es: "ferry",
  et: "laevapiletid",
  fi: "lautta",
  fr: "ferry",
  hr: "trajekt",
  it: "traghetti",
  ja: "ferry",
  ko: "ferry",
  no: "ferge",
  nl: "ferry",
  pl: "ferry",
  pt: "ferry",
  sq: "ferry",
  sv: "farja",
  tr: "feribot",
  zh: "ferry",
};

export const LANGUAGE_FERRY_ROUTES_TERM_COMBINATIONS: {
  [key: string]: string;
} = {
  da: "faergeruter",
  de: "faehrverbindungen",
  el: "dromologia-ploion",
  en: "ferry-routes",
  es: "rutas-ferry",
  et: "parvlaeva-marsruudid",
  fi: "lauttareitit",
  fr: "lignes-et-billets-de-ferry",
  hr: "trajektne-rute",
  it: "percorsi-in-traghetto",
  ja: "ferry-routes",
  ko: "ferry-routes",
  nb: "fergeruter",
  nl: "veerbootroutes",
  no: "fergeruter",
  pl: "trasy-promowe",
  pt: "rotas-de-ferry",
  sq: "linjat-e-trageteve",
  sv: "farjerutter",
  tr: "feribot-rotalari",
  zh: "ferry-routes",
};

export const LANGUAGE_FERRY_COMPANIES_TERM_COMBINATIONS: {
  [key: string]: string;
} = {
  da: "faergeselskaber",
  de: "faehrgesellschaften",
  el: "aktoploikes-etaireies",
  en: "ferry-companies",
  es: "companias-de-ferry",
  et: "parvlaevafirmad",
  fi: "lauttayhtiot",
  fr: "compagnies-de-ferry",
  hr: "trajektne-tvrtke",
  it: "compagnie-traghetti",
  ja: "ferry-companies",
  ko: "ferry-companies",
  nb: "fergeruter",
  nl: "veermaatschappijen",
  no: "ferjeselskaper",
  pl: "firmy-promowe",
  pt: "empresas-de-ferry",
  sq: "kompanit-e-trageteve",
  sv: "farjeforetag",
  tr: "feribot-sirketleri",
  zh: "ferry-companies",
};

export const SUPPORTED_LANGUAGES: SupportedLanguages = {
  da: { label: "Dansk", code: "da_DK" },
  de: { label: "Deutsch", code: "de_DE" },
  el: { label: "Ελληνικά", code: "el_GR" },
  en: { label: "English", code: "en_IE" },
  es: { label: "Español", code: "es_ES" },
  et: { label: "Eesti", code: "et_EE" },
  fr: { label: "Français", code: "fr_FR" },
  hr: { label: "Hrvatski", code: "hr_HR" },
  it: { label: "Italiano", code: "it_IT" },
  nl: { label: "Nederlands", code: "nl_NL" },
  no: { label: "Norsk", code: "no_NO" },
  pl: { label: "Polski", code: "pl_PL" },
  pt: { label: "Português", code: "pt_PT" },
  fi: { label: "Suomi", code: "fi_FI" },
  sv: { label: "Svenska", code: "sv_SE" },
  sq: { label: "Shqip", code: "sq_AL" },
  tr: { label: "Türkçe", code: "tr_TR" },
  zh: { label: "中文 (简体)", code: "zh_CN" },
  ko: { label: "한국어", code: "ko_KR" },
  ja: { label: "日本語", code: "ja_JP" },
};

export const BILINGUAL_ELIGIBLE_LANGUAGES = ["el_GR", "en_IE"];
