import { Port } from "@/global-types/ports";
import { isGreekText } from "@/utils/bilingual-utils";
import { selectors } from "@/store/searchMask";
import store from "@/store";

const getPortData = (portID: string, portsData?: Port[]) =>
  portsData?.find((port) => port.ferryOwnLocationID === portID);

export const getPortAbbr = (portID: string, portsData?: Port[]) =>
  getPortData(portID, portsData)?.locationAbbr || "";

export const getPortLocationName = (
  portID: string,
  portsData: Port[],
  lang: string,
) => {
  const isDeparturePortInGreek = selectors.getIsDeparturePortInGreek(
    store.getState(),
  );

  const shouldUseGreek =
    (lang === "el" &&
      (isDeparturePortInGreek == null || isDeparturePortInGreek)) ||
    (lang !== "el" && isDeparturePortInGreek);

  const portsDataBasedOnLanguage = portsData?.filter((port) =>
    shouldUseGreek
      ? isGreekText(port.locationName)
      : !isGreekText(port.locationName),
  );

  return getPortData(portID, portsDataBasedOnLanguage)?.locationName || "";
};

export const getFerryLocationId = ({
  abbr,
  portsData,
}: {
  abbr: string;
  portsData: Port[];
}) =>
  portsData.find(({ locationAbbr }) => locationAbbr === abbr)
    ?.ferryOwnLocationID || "";
