import { RootState } from "../types";
import { REDUCER_NAME } from "./constants";

const getIsDeparturePortInGreek = (state: RootState) =>
  state[REDUCER_NAME].isDeparturePortInGreek;

const getOpenReturnField = (state: RootState) =>
  state[REDUCER_NAME].openReturnField;

const getOpenDateField = (state: RootState) =>
  state[REDUCER_NAME].openDateField;

const selectors = {
  getIsDeparturePortInGreek,
  getOpenReturnField,
  getOpenDateField,
};

export default selectors;
