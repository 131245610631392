import { RecentSearch } from "./types";
import { NAMESPACE } from "./constants";

export const saveRecentSearch = (newSearch: RecentSearch) => {
  const storedData: RecentSearch[] = JSON.parse(
    localStorage.getItem(NAMESPACE) || "[]",
  );

  // Remove any existing search with the same departurePorts and arrivalPorts
  const filteredData = storedData.filter(
    (search) =>
      search.departurePorts !== newSearch.departurePorts ||
      search.arrivalPorts !== newSearch.arrivalPorts,
  );

  // Add the new search to the beginning of the list
  filteredData.unshift(newSearch);

  // Sort by date (newest first) and keep only the five newest entries
  const limitedEntries = filteredData.slice(0, 5);

  // Save back to local storage
  localStorage.setItem(NAMESPACE, JSON.stringify(limitedEntries));
};
