"use client";

import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styles from "./toggle-button.module.css";
import { ToggleButtonItem } from "./types";

type Props<T> = {
  items: ToggleButtonItem<T>[];
  selected: T;
  className?: string;
  handleClick: (selected: T) => void;
};

const ToggleButton = <T,>({
  items,
  selected,
  className,
  handleClick,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [indicatorStyle, setIndicatorStyle] =
    useState<React.CSSProperties | null>(null);

  useEffect(() => {
    const updateIndicatorStyle = () => {
      const selectedButton = containerRef.current?.querySelector(
        `[data-value="${selected}"]`,
      ) as HTMLButtonElement;

      if (selectedButton && containerRef.current) {
        const rect = selectedButton.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        setIndicatorStyle({
          width: `${rect.width}px`,
          left: `${rect.left - containerRect.left}px`,
          height: `${rect.height}px`,
        });
      }
    };

    updateIndicatorStyle();
  }, [selected]);

  return (
    <div className={clsx(styles.root, className)} ref={containerRef}>
      {items.map((item, index) => (
        <button
          key={index}
          data-value={item.value}
          className={clsx(styles.item, {
            "text-white": indicatorStyle && selected === item.value,
          })}
          onClick={() => handleClick(item.value)}
        >
          {item.label}
        </button>
      ))}
      {indicatorStyle && (
        <div className={clsx(styles.animated_item)} style={indicatorStyle} />
      )}
    </div>
  );
};

export default ToggleButton;
