"use client";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@/store/routes";
import { TripType } from "@/store/routes/types";
import ToggleButton from "../toggle-button";

interface Item {
  value: TripType;
  label: string;
}
interface Props {
  items: Item[];
  className?: string;
}

const TripTypes = ({ items, className }: Props) => {
  const dispatch = useDispatch();
  const selected = useSelector(selectors.getTripType);

  const handleClick = (value: TripType) => {
    if (value === selected) return;

    dispatch(actions.changeTripType(value));
  };
  return (
    <ToggleButton
      className={className}
      items={items}
      selected={selected}
      handleClick={handleClick}
    />
  );
};

export default TripTypes;
