"use client";

import dynamic from "next/dynamic";
import { Translations } from "@/global-types/translations";

const Mobile = dynamic(() => import("./mobile"));
const Desktop = dynamic(() => import("./desktop"));

interface Props {
  isMobile: boolean;
  label: string;
  routeIndex: number;
  minDate: string;
  maxDate?: string;
  disabled?: boolean;
  translations: Translations | undefined;
}

const DateField = ({
  isMobile,
  label,
  routeIndex,
  minDate,
  maxDate,
  disabled,
  translations,
}: Props) => {
  const Component = isMobile ? Mobile : Desktop;

  return (
    <Component
      label={label}
      routeIndex={routeIndex}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      isMobile={isMobile}
      translations={translations}
    />
  );
};

export default DateField;
