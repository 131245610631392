"use client";

import { useEffect } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as routeActions,
  selectors as routeSelectors,
} from "@/store/routes";
import { actions as searchMaskActions } from "@/store/searchMask";
import { Port } from "@/global-types/ports";
import { TripType } from "@/store/routes/types";

import TripTypes from "./components/trip-types";
import Multitrip from "./components/multitrip";
import SingleTrip from "./components/single-trip";

import styles from "./search-mask.module.css";
import { Translations } from "@/global-types/translations";
import { getFerryLocationId } from "@/utils/port-utils";
import { TRIP_TYPE_TERMS } from "./components/trip-types/constants";

interface Props {
  className?: string;
  isMobile: boolean;
  portsData: Port[];
  translations: Translations | undefined;
  lang: string;
}

const SearchMask = ({
  portsData,
  translations,
  isMobile,
  className,
  lang,
}: Props) => {
  const dispatch = useDispatch();
  const isMultitrip = useSelector(routeSelectors.getIsMultitrip);
  const searchParams = useSearchParams();

  useEffect(() => {
    const departure = searchParams.get("dep");
    const arrival = searchParams.get("arr");
    if (!portsData) return;

    const departurePortId = departure
      ? getFerryLocationId({ portsData, abbr: departure })
      : undefined;
    const arrivalPortId = arrival
      ? getFerryLocationId({ portsData, abbr: arrival })
      : undefined;

    if (!departurePortId) return;
    const date = dayjs().add(1, "day").format("YYYY-MM-DD");

    dispatch(
      routeActions.createTripFromUrl({
        departurePortId,
        arrivalPortId: arrivalPortId ?? "",
        date,
      }),
    );

    const url = new URL(window.location.href);
    if (url.search) {
      url.search = "";
      window.history.replaceState({}, document.title, url.toString());
    }
  }, [searchParams, portsData, dispatch]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const [navigationEntry] = performance.getEntriesByType(
      "navigation",
    ) as PerformanceEntry[];

    if (
      ["back_forward", "reload"].includes(
        (navigationEntry as PerformanceNavigationTiming).type,
      )
    ) {
      return;
    }

    dispatch(routeActions.resetRoutes());
    dispatch(searchMaskActions.changeIsDeparturePortInGreek(false));
  }, [dispatch]);

  const items = Object.entries(TRIP_TYPE_TERMS).map(
    ([key, { term, fallbackTranslation }]) => ({
      value: key as TripType,
      label: translations?.[term] || fallbackTranslation,
    }),
  );

  return (
    <div className={className}>
      <div className="container">
        <div className={styles.card}>
          <TripTypes items={items} className="self-start" />
          {isMultitrip ? (
            <Multitrip
              portsData={portsData}
              translations={translations}
              isMobile={isMobile}
              lang={lang}
            />
          ) : (
            <SingleTrip
              portsData={portsData}
              translations={translations}
              isMobile={isMobile}
              lang={lang}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchMask;
