import { Route } from "./types";
import initialState from "./initialState";

export const handleOneWayTrip = (routes: Route[]): Route[] => {
  return [routes[0]];
};

export const handleRoundTrip = (routes: Route[]): Route[] => {
  const firstRoute = routes[0];
  const roundTripRoute = {
    ...firstRoute,
    departurePortId: firstRoute.arrivalPortId,
    arrivalPortId: firstRoute.departurePortId,
    date: routes[1]?.date || "",
  };
  return [firstRoute, roundTripRoute];
};

export const handleMultiTrip = (routes: Route[]): Route[] => {
  const isEmptyRoute = (route: Record<string, string>): boolean => {
    return Object.values(route).every((value) => value === "");
  };
  if (routes.length > 1 && isEmptyRoute(routes[1])) {
    routes.pop();
  }
  return routes;
};

export const isOutbound = (index: number, routes: Route[]) =>
  index < 0 || index > routes.length - 1;

export const updateRouteProperty = ({
  state,
  index,
  property,
  value,
}: {
  state: typeof initialState;
  index: number;
  property: keyof Route;
  value: string;
}) => {
  if (isOutbound(index, state.routes)) return;
  state.routes[index][property] = value;
};

export const resetArrivalIdAndDate = ({
  index,
  value,
  state,
}: {
  index: number;
  value: string;
  state: typeof initialState;
}) => {
  if (state.routes[index].departurePortId !== value) {
    updateRouteProperty({
      state,
      index,
      property: "date",
      value: "",
    });
    updateRouteProperty({
      state,
      index,
      property: "arrivalPortId",
      value: "",
    });
  }
};

export const removeFromRoutes = (routes: Route[], index: number) => {
  return [...routes.slice(0, index), ...routes.slice(index + 1)];
};
