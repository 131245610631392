"use client";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectors } from "@/store/routes";
import { Route } from "@/store/routes/types";

import { Port } from "@/global-types/ports";

import DateField from "../date-field";
import SearchButton from "../search-button";
import Autocomplete from "../autocomplete";
import Swap from "../swap";

import AddRoute from "./add-route";
import RemoveRoute from "./remove-route";

import { getMatchingPorts } from "../utils";

import styles from "./multitrip.module.css";
import { getPortLocationName } from "@/utils/port-utils";
import { Translations } from "@/global-types/translations";

interface Props {
  isMobile: boolean;
  portsData: Port[];
  translations: Translations | undefined;
  lang: string;
}

const Multitrip = ({ portsData, translations, isMobile, lang }: Props) => {
  const routes = useSelector(selectors.getRoutes);

  const renderRoute = (route: Route, index: number) => {
    const { departurePortId, arrivalPortId, date } = route;
    const minDate =
      index === 0 ? dayjs().format("YYYY-MM-DD") : routes[index - 1]?.date;
    const maxDate = routes[index + 1]?.date;

    return (
      <div key={index} className={styles.route_grid}>
        <p className={styles.route_index}>{index + 1}</p>
        <div className={styles.route_grid_item}>
          <Autocomplete
            isMobile={isMobile}
            portsData={portsData}
            id={`from-${index}`}
            placeholder={translations?.dep || "From"}
            routeType="departure"
            routeIndex={index}
            initialPortLocationName={getPortLocationName(
              departurePortId,
              portsData,
              lang,
            )}
            translations={translations}
            lang={lang}
          />
          <Autocomplete
            isMobile={isMobile}
            portsData={getMatchingPorts(portsData, departurePortId)}
            id={`to-${index}`}
            placeholder={translations?.arr || "To"}
            routeType="arrival"
            routeIndex={index}
            disabled={!departurePortId}
            initialPortLocationName={getPortLocationName(
              arrivalPortId,
              portsData,
              lang,
            )}
            translations={translations}
            lang={lang}
          />
          <Swap routeIndex={index} className={styles.swap_button} />
        </div>
        <DateField
          label={date ? dayjs(date).format("DD MMM") : ""}
          disabled={!departurePortId}
          routeIndex={index}
          minDate={minDate}
          maxDate={maxDate}
          isMobile={isMobile}
          translations={translations}
        />
        <RemoveRoute routeIndex={index} />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-1 flex-col gap-4">
        {routes.map(renderRoute)}
      </div>
      <div className={styles.route_grid_item}>
        <AddRoute translations={translations} />
        <SearchButton
          lang={lang}
          portsData={portsData}
          translations={translations}
        />
      </div>
    </div>
  );
};

export default Multitrip;
