"use client";

import dynamic from "next/dynamic";
import { TextFieldProps } from "@/components/ui/text-field";
import { Port } from "@/global-types/ports";
import { Translations } from "@/global-types/translations";

const Mobile = dynamic(() => import("./mobile"));
const Desktop = dynamic(() => import("./desktop"));

interface Props extends TextFieldProps {
  isMobile: boolean;
  portsData: Port[];
  routeType: "departure" | "arrival";
  routeIndex: number;
  initialPortLocationName: string;
  translations: Translations | undefined;
  lang: string;
}

const Autocomplete: React.FC<Props> = ({
  isMobile,
  portsData,
  routeType,
  routeIndex,
  initialPortLocationName,
  lang,
  ...rest
}) => {
  const Component = isMobile ? Mobile : Desktop;
  return (
    <Component
      portsData={portsData}
      routeType={routeType}
      routeIndex={routeIndex}
      initialPortLocationName={initialPortLocationName}
      lang={lang}
      {...rest}
    />
  );
};

export default Autocomplete;
